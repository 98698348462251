import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import { Typography } from '../components/Typography';
import { Box } from '../components/Box';
import { Wrapper } from '../components/Wrapper';
import { Section } from '../components/Section';
import Projects from '../sections/Projects';

import Hero from '../components/Hero';
import { Flex } from '../components/Flex';
import Post from '../components/Post';

const index = ({ data }) => {
  const blogPosts = data.allSanityPost.edges;
  const { site } = data;
  return (
    <Layout>
      <Seo seoData={site.siteMetadata} />
      <Hero css={{
        py: '$6',
        '@sm': {
          py: '$1',
        },
      }}
      >
        <Typography
          css={{
            '@sm': {
              mt: '$4',
            },
          }}
          as="h1"
        >
          Hi, I&apos;m Mike
        </Typography>
        <h2>Front-End Developer out of Northern Utah.</h2>
      </Hero>

      <Wrapper name="aboutMe">
        <Section
          css={{
            py: '$7',
            '@sm': {
              py: '$1',
            },
          }}
        >
          <Flex
            mx="auto"
            jc="around"
            ai="center"
            css={{
              '@lg': {
                flexWrap: 'wrap',
              },
            }}
          >
            <Box p="4">
              <StaticImage
                height={500}
                layout="constrained"
                className="mike"
                src="../images/mike-trip.jpg"
                alt="mike on a trip"
              />
            </Box>
            <Box
              p="4"
              css={{
                maxWidth: '$sm',
                '@lg': {
                  maxWidth: 'initial',
                },
              }}
            >
              <h2>About Me</h2>
              <Typography>
                Always up for a challenge, I have been working as a web
                developer almost 7 years now. Currently, I&apos;m a software
                engineer at Clearlink. My team is currently building a
                custom CMS that will increase production in creating new
                websites and pages for all our brands. The project we are
                working on uses React, GatsbyJS, Next-JS, Emotion, GraphQL,
                Cloudinary, and Contentful. Utilizing modern practices and
                technologies like yarn workspaces, webpack bundling, cypress
                (for testing), and storybook. We were able to significantly
                increase our site&apos;s speeds and performance and overall
                process in getting a site created and deployed.
              </Typography>
            </Box>
          </Flex>
        </Section>
      </Wrapper>

      <Wrapper name="blog">
        <Section
          py="5"
        >
          <Box>
            <Typography as="h2" ta="center">
              Blog
            </Typography>
            <Typography
              ta="center"
              css={{
                maxWidth: '$lg',
                width: 'auto',
                margin: 'auto',
                '@md': {
                  maxWidth: '$sm',
                  ta: 'left',
                },
              }}
            >
              I think that a great way to learn is to try and explain the how
              and why when it comes to how certain things were built and the
              decisions behind them. I wanna try and explain the decisions and
              thoughts I have while creating / coding something. I find myself
              in tutorial hell a lot and think this could help me solidify
              that information by explaining on it further. I am not the best
              developer out there, but I want to always try and improve my
              skills and get better.
            </Typography>
          </Box>
          <br />
          {blogPosts.map(({ node }) => {
            const {
              description,
              title,
              date,
              bgColor,
              mainImage,
              slug,
            } = node;

            return (
              <Post
                key={slug.current}
                to={`/${slug.current}`}
                bgColor={`${bgColor}`}
                icon={mainImage.asset.gatsbyImageData.images.fallback.src}
                title={title}
                description={description}
                date={date}
              />
            );
          })}
        </Section>
      </Wrapper>
      <Projects name="projects" />

    </Layout>
  );
};

export default index;

export const query = graphql`
  query homeQuery {
    site {
      siteMetadata {
        title
        description
        author
        url
      }
    }
    allSanityPost {
      edges {
        node {
          id
          title
          slug {
            current
          }
          description
          bgColor
          mainImage {
            asset {
              gatsbyImageData
            }
          }
          _createdAt(formatString: "MM DD YY")
        }
      }
    }
  }
`;
