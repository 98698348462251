import React from 'react';
import { styled } from '../../../stitches.config';
import { Typography } from '../Typography';
import { backgroundColorVariants } from '../../styles/variants';

const Project = ({
  children, url, text, description, bg, ...props
}) => (
  <Wrapper css={{ maxWidth: '$sm', width: '100%' }}>
    <Box bg={bg} {...props}>
      {children}
      <Typography ta="center" as="h4">
        {text}
      </Typography>
    </Box>
    <div>
      <Typography fontSize="4">
        {description}
        <Typography
          css={{
            bb: `solid 1px $${bg}`,
            textDecoration: 'none',
            transition: 'border-bottom .2s',
            '&:hover': {
              bb: `solid 3px $${bg}`,
            },
          }}
          target="_blank"
          rel="noreferrer"
          href={url}
          as="a"
        >
          Visit Site
        </Typography>
      </Typography>
    </div>
  </Wrapper>
);

export default Project;

const Wrapper = styled('div', {
  p: {
    textAlign: 'center',
    padding: '10px',
    a: {
      paddingLeft: '4px',
      textDecoration: 'none',
      transition: 'border-bottom-width 0.3s',
      '&:hover': {
        borderBottomWidth: '3px',
        transition: 'border-bottom-width 0.3s',
      },
    },
  },
});

const Box = styled(
  'div',
  {
    position: 'relative',
    width: 'auto',
    padding: '15px 30px',
    backgroundColor: '$tertiary',
    borderRadius: '$1',
    overflow: 'visible',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    h4: { textShadow: '$small' },
    svg: { width: '100%', maxHeight: '150px', transition: 'transform 0.2s' },
    '.bruner': { height: '200px' },
    '.catan': { maxWidth: '150px' },
  },
  backgroundColorVariants,
);
