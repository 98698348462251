import React from 'react';
import { Link } from 'gatsby';
import { ArrowRightIcon } from '@radix-ui/react-icons';
import { styled } from '../../../stitches.config';
import { Typography } from '../Typography';
import { Wrapper } from '../Wrapper';
import { Flex } from '../Flex';

const Post = ({
  title, description, icon, to, bgColor,
}) => (
  <PostWrapper
    flex
    bg="light3"
    my="6"
    mx="auto"
    css={{
      flexDirection: 'row',
      maxWidth: '$lg',
      br: '$2',
      '@md': {
        flexDirection: 'column',
      },
      '@sm': {
        maxWidth: '$xxs',
      },
    }}
  >
    <Flex
      css={{
        btlr: '$2',
        bblr: '$2',
        maxWidth: '$xxs',
        width: '100%',
        ai: 'center',
        jc: 'center',
        '@md': {
          maxWidth: 'initial',
          btrr: '$2',
          bblr: '0',
        },
      }}
      bg={bgColor}
    >
      <SvgContainer>
        <img src={icon} alt="blog icon" />
      </SvgContainer>
    </Flex>

    <Flex
      flex
      fd="column"
      ai="between"
      jc="between"
      p="5"
      css={{
        flexGrow: '3',
      }}
    >

      <div>
        <Typography pr="4" as="h3" mb="0">
          {title}
        </Typography>
        <Typography
          css={{
            fontSize: '$2',
            lineHeight: '$3',
            pr: '$4',
          }}
        >
          {description}
        </Typography>
      </div>

      <Flex flex>

        <StyledLink
          css={{
            alignSelf: 'center',
            marginLeft: 'auto',
          }}
          to={to}
          color={bgColor}
        >
          read more <ArrowRightIcon size="20" />
        </StyledLink>

      </Flex>
    </Flex>
  </PostWrapper>
);
export default Post;

const SvgContainer = styled('div', {
  width: 'auto',
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderTopLeftRadius: '$3',
  borderBottomLeftRadius: '$3',
  img: { maxWidth: '180px', maxHeight: '180px', padding: '30px' },
});

const PostWrapper = styled(Wrapper, {
  height: '100%',
  borderRadius: '3',
});

const StyledLink = styled(Link, {
  color: '$text',
  display: 'inline-block',
  textDecoration: 'none',
  '&:hover': { '&::after': { width: '100%', transition: '0.4s' } },
  '&::after': {
    content: "''",
    display: 'block',
    width: '0',
    height: '2px',
    transition: 'width 0.3s',
  },
});
