import { styled } from '../../../stitches.config';
import { Wrapper } from '../Wrapper';
import { paddingVariants, flexVariants } from '../../styles/variants';

export const Hero = styled(Wrapper, {
  position: 'relative',
  section: { padding: '0' },
  '.container': {
    width: '44%',
    padding: '$4',
    '@lg': { width: 'auto', marginTop: '0' },
  },
});

export const HeroBackground = styled('div', {
  backgroundColor: '$backgroundInvert',
  width: '50%',
  position: 'absolute',
  top: '0',
  right: '0',
  height: '85%',
  overflow: 'hidden',
  '@lg': { position: 'relative', width: 'auto', display: 'none' },
  '.halfCircle': {
    width: '150px',
    height: '75px',
    display: 'inline-block',
    margin: '0 1em 1em 0',
    '&:nth-of-type(1)': {
      backgroundColor: '$base',
      borderTopLeftRadius: '150px',
      borderTopRightRadius: '150px',
    },
    '&:nth-of-type(2)': {
      width: '75px',
      height: '150px',
      backgroundColor: '$primary',
      borderTopLeftRadius: '150px',
      borderBottomLeftRadius: '150px',
    },
    '&:nth-of-type(3)': {
      backgroundColor: '$success',
      borderBottomLeftRadius: '150px',
      borderBottomRightRadius: '150px',
    },
    '&:nth-of-type(4)': {
      backgroundColor: '$light3',
      borderBottomRightRadius: '150px',
      borderTopRightRadius: '150px',
      width: '75px',
      height: '150px',
    },
  },
}, paddingVariants);
