import React from 'react';

import { Wrapper } from '../../components/Wrapper';
import { Section } from '../../components/Section';
import { Grid } from '../../components/Grid';
import Project from '../../components/Project';
import { Typography } from '../../components/Typography';

import Coding from '../../images/Coding.svg';
import Gameboy from '../../images/gameboy.svg';
import Website from '../../images/website.svg';
import Ipod from '../../images/Ipod.svg';
import Carousel from '../../images/Carousel.svg';
import Bruner from '../../images/Bruner.svg';
import BrunerNight from '../../images/BrunerNight.svg';
import Establish from '../../images/Establish.svg';
import Catan from '../../images/catan.svg';
import CsvUploader from '../../images/csvUploader.svg';
import NextJSColors from '../../images/next-js-colors.svg';
import ChartJsIcon from '../../images/graph_chart.svg';
import { ThemeContext } from '../../styles/ThemeProvider';

const Projects = ({ name }) => {
  const { colorMode } = React.useContext(ThemeContext);
  if (!colorMode) {
    return null;
  }

  return (
    <Wrapper bg="light2" name={name}>
      <Section pt="5">
        <Typography as="h3">Check out some of my stuff.</Typography>
        <Grid pt="5">
          <Project
            bg="success"
            url="https://next-js-colors.vercel.app/"
            text="Stitches + Next-JS"
            description="A theme builder for using Stitches with Next-JS "
          >
            <NextJSColors />
          </Project>

          <Project
            bg="secondary"
            url="https://chart-js-demo.vercel.app/"
            text="React + Chart.js"
            description="Wanted to give Chart.js a try and decided to build a cool demo.  "
          >
            <ChartJsIcon />
          </Project>

          <Project
            bg="warning"
            url="https://mikes-meme-generator.netlify.app/"
            text="Mikes Meme Generator"
            description="While learning Gatsby, I thought it would be cool to test my knowledge with promises and api calls to make a Meme Generator."
          >
            <Coding />
          </Project>
          <Project
            bg="base"
            url="https://mikeytown19.github.io/tic-tac-toe-react/"
            text="Tic Tac Toe"
            description="Using JavaScript / CSS, I made a Tic-Tac-Toe game."
          >
            <Gameboy />
          </Project>
          <Project
            bg="success"
            url="https://www.votejimharvey.com/"
            text="Jim Harvey"
            description="In 2016, I built this website using Gatsby + Netlify and learning a lot more about seo and analytics. It was one of the first Gatsby projects I did."
          >
            <Website />
          </Project>
          <Project
            bg="secondary"
            url="https://soundcloud.com/michaeljharvey/on-my-own"
            text="My soundcloud music"
            description="I've always loved singing and making music, here's a couple songs I've released."
          >
            <Ipod />
          </Project>
          <Project
            bg="primary"
            url="https://mikeytown19.github.io/carousel/"
            text="Carousel"
            description="Early in my career I was asked to white board a carousel during a job interview and totally bombed it. After knowing I failed I spent the weekend making one to prove to myself I could."
          >
            <Carousel />
          </Project>

          <Project
            bg="bruner"
            url="https://bruner-dynamics.netlify.app/"
            text="For the future"
            description="When learning gatsby, my team was individually tasked to build this amazing site to test our ability. Even though the test ended up being a waste of time. I think the site turned out cool."
          >
            {colorMode !== 'dark' ? <Bruner /> : <BrunerNight />}
          </Project>

          <Project
            bg="tertiary"
            url="https://establishdesign.com/"
            text="Establish Design"
            description="A site we helped create for a client to show off architectural and interior designs. "
          >
            <Establish />
          </Project>
          <Project
            bg="warning"
            url="https://catan-board.netlify.app/"
            text="Catan Board Generator"
            description="I love catan and thought this would be easier to set up a board every time we decided to play a game. "
          >
            <Catan />
          </Project>
          <Project
            bg="success"
            url="https://mikescsvuploader.netlify.app/"
            text=".csv Uploader"
            description="Using Nuxt / Vue / Tailwind, This lets you upload only CSV files and are displayed and stored locally."
          >
            <CsvUploader />
          </Project>
        </Grid>
      </Section>
    </Wrapper>
  );
};

export default Projects;
