import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import { Container } from '../Container';
import * as Styled from './Hero.styles';

const halfCircle = (positionY, positionX, dragConstraints, speed) => {
  const move = {
    duration: 10,
    repeatType: 'mirror',
    repeat: Infinity,
    ease: 'easeInOut',
  };

  return (
    <motion.div
      animate={{
        x: positionX * speed,
        y: positionY * speed,
      }}
      transition={move}
      initial={{ y: positionY, x: positionX }}
      dragConstraints={dragConstraints}
      className="halfCircle"
      drag
    />
  );
};

const Hero = ({ children, ...props }) => {
  const constraintsRef = useRef(null);
  return (
    <Styled.Hero {...props}>
      <Container>
        <div className="container">{children}</div>
      </Container>

      <Styled.HeroBackground ref={constraintsRef}>
        {halfCircle(100, -30, constraintsRef, 0.5)}
        {halfCircle(300, 300, constraintsRef, 0.9)}
        {halfCircle(200, -50, constraintsRef, 0.4)}
        {halfCircle(90, 140, constraintsRef, 0.2)}

      </Styled.HeroBackground>
    </Styled.Hero>
  );
};

export default Hero;
