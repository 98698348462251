import React from 'react';
import { styled } from '../../../stitches.config';
import { paddingVariants } from '../../styles/variants';

export const Grid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
  gridGap: '5rem',
  gridAutoFlow: 'dense',
  paddingBottom: '$6',
  paddingTop: '$6',
  justifyItems: 'center',
  '@lg': { gridTemplateColumns: 'repeat(auto-fit, 100%)' },
  '@md': { paddingTop: '$4' },
}, paddingVariants);
